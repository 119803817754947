import React, {createContext, useState} from 'react';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Service/Services';
import './App.css'
import ScrollToTop from "react-scroll-to-top";
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import NavbarMobile from './components/Navbar/NavbarMobile';

export const ThemeContext = createContext(null);


const App = () => {

  const [theme, setTheme] = useState("dark");

  const handleChangeTheme = () => {
    setTheme((current) => (current === "light" ? "dark" : "light"))
  };

  return (
    <ThemeContext.Provider value={{theme, handleChangeTheme}}>

    <div className='main-theme-compo' id={theme}>  
        <Navbar/>
        <NavbarMobile/>
        <Home theme={theme} handleChangeTheme={handleChangeTheme}/>
        <About/>
        <Services/>
        <Contact/>
        <Footer/>
    </div>
      <ScrollToTop smooth 
      top='300'
      color='white'
      height='20'
      width='20'
      style={{borderRadius:'90px', backgroundColor:'aqua'}}
      />


    </ThemeContext.Provider>
  );
}

export default App;
