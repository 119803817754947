import React, {useState} from 'react'
import "./navbar.css"
import {Link} from 'react-scroll';

const Navbar = () => {

    const [navScrollColor, setNavScrollColor] = useState(false);

    const onChangeNavColor = () =>{
        if(window.scrollY >= 900){
            setNavScrollColor(true);
        }else{
            setNavScrollColor(false);
        }
    }
    window.addEventListener("scroll", onChangeNavColor);

  return (
    <nav className={navScrollColor ? 'navbar-scroll-color navbar-main': 'navbar-main'}>
        <ul>
            <li className='nav-item'>
                <Link to='home' spy={true} offset={-10} duration={100}>
                Home
                </Link>
            </li>
           
            <li className='nav-item'>
            <Link to='about' spy={true} offset={10} duration={100}>
                About
            </Link>
            </li>
            
            <li className='nav-item'>
            <Link to='service' spy={true} offset={-90} duration={100}>
                Services
            </Link>
            </li>            
            
            <li className='nav-item'>
            <Link to='contact' spy={true} offset={-150} duration={100}>
                Contact
            </Link>
            </li>
            
        </ul>
    </nav>
  )
}

export default Navbar