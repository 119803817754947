import React from 'react'
import "./home.css"
import Typewriter from 'typewriter-effect';
import MintyFace from "../../image/mint_face.png";
import SunglassesMinty from "../../image/sunglasses_minty.png";

const Home = ({theme, handleChangeTheme}) => {
    return (
      <div className='container-fluid home' id='home'>
        <div className='theme-change' onClick={() => handleChangeTheme()}>
        {theme === 'light' ? (
          <p>
            <img src={MintyFace} alt="Logo" width={40} />
          </p>
          ) : (
            <p>
              <img src={SunglassesMinty} alt="Sunnyminty" width={40} /> 
            </p>
            
            )}
        </div>
     
        <div className='container home-content'>
        <img src={MintyFace} alt="Logo" width={200} />
          <h1 className='title'>Domesday Analytics</h1>
          <h3 className='typewriter'>
            <Typewriter
              options={{
                strings: ["Your Data.", "Your Decisions."],
                autoStart: true,
                loop: true,
                delay: 3,
              }}
            />
          </h3>
        </div>
      </div>
    );
  };
  
  export default Home;