import React, { useState } from 'react'
import {Link} from 'react-scroll'
import "./navbarmobile.css"
import {GiHamburgerMenu} from 'react-icons/gi'

const NavbarMobile = () => {
    const [open, setOpen] = useState(false);

    const handleNavbarOpen = () => {
        setOpen(!open)
    };

  return (
    <div className='responsive-mobile-view'>
        <div className='container-fluid mobile-view-header'>
            <h3> Domesday Analytics</h3>
            <p><GiHamburgerMenu size={25} onClick={handleNavbarOpen}/></p>
        </div>

        {open? (<div className='mobile-nav'>
        <ul>
            <li className='nav-item'>
                <Link to='home' spy={true} smooth={true} offset={-100} duration={100}>
                    Home
                </Link>
            </li>
    
            
            <li className='nav-item'>
                <Link to='about' spy={true} smooth={true} offset={-45} duration={100}>
                    About
                </Link>
            </li>
    
    
            <li className='nav-item'>
                <Link to='services' spy={true} smooth={true} offset={-100} duration={100}>
                    Services
                </Link>  
            </li>
        
            <li className='nav-item'>
                <Link to='contact' spy={true} smooth={true} offset={-100} duration={100}>
                    Contact
                </Link>
            </li>
    

        </ul>
    </div>):null}

        
    </div>
  )
}

export default NavbarMobile