import React from 'react'
import contac_img from '../../image/mint_face.png'
import {FiSend} from "react-icons/fi"
import {BsTwitter} from 'react-icons/bs'
import { FaFacebookSquare, FaInstagram} from 'react-icons/fa'
import Fade from 'react-reveal/Zoom';
import "./contact.css"

const Contact = () => {

    const emailAddress = 'info.domesdayanalytics@gmail.com';
    return (
        <Fade>
        <div className='container contact-section'id='contact'>
            <div className='row'>
                <div className='col-xl-5 col-lg-5 col-md-5 col-sm-5'>
                    <div className='image-class'>
                        <img src={contac_img} alt='contact'/>
                    </div>
                </div>

                <div className='col-xl-7 col-lg-7 col-md-7 col-sm-7'>
                    <div className='contact-form-design'>
                        <div className='text-center'>
                            <h5>Contact Us</h5>
                            <p>We are dedicated to providing top-notch solutions tailored 
                            to meet the specific needs of companies of all sizes. 
                            Whether you are a small startup, a mid-sized business, or a 
                            large enterprise, our services are designed to cater to your 
                            requirements. Our team of experts is passionate about helping 
                            businesses grow and succeed.

                            No matter the scale of your operations, we are committed to 
                            delivering exceptional services that drive results. 
                            From innovative software solutions to strategic consulting, 
                            we offer a comprehensive range of services to support your 
                            business objectives.
                            
                            If you're looking for a reliable partner to propel your company forward, 
                            we would love to connect with you. Reach out to us today, 
                            and let's start a conversation about how we can help your business thrive. 
                            Together, we can unlock the full potential of your company and pave 
                            the way for a prosperous future. Get in touch with us, 
                            and let's embark on this exciting journey together.</p>
                        </div>
                            <div className='button-submit'>
                                <a href={`mailto:${emailAddress}`}>
                                <p>Email <FiSend size={20}/></p>
                                </a>
                            </div>
                            <div className='icon-button'>
                            <p> 
                                <a href="https://www.facebook.com">
                                    <FaFacebookSquare size={40}/>
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/domesdayanalytics/">
                                    <FaInstagram size={40}/>
                                </a>
                            </p>
                            <p>
                                <a href='https://twitter.com/domesdayltd'>
                                    <BsTwitter size={40}/>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Fade>
    )
    }

    export default Contact