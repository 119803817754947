import React from 'react'
import about from "../../image/about_image.png"
import {FcGlobe, FcShipped, FcStatistics} from 'react-icons/fc'
import Fade from 'react-reveal/Fade';
import './about.css'

const About = () => {
  return (
    <div className='container about-section'id='about'>
        <div className='row'>
        <Fade left>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div className='about-image'>
                    <img src={about} alt='mint face circle' height='400px' width='400px'/>
                </div>
            </div>
        </Fade>
            <Fade right>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div className='about-details'>
                    <div className='about-title'>
                        <h5>About</h5>
                        <span className='line'></span>
                    </div>
                    <p className='about-text'>
                        Domesday Analytics is a data-driven company that aims to level
                        the playing field for small businesses by providing them with the 
                        tools and resources to leverage data science effectively. 
                        
                    </p>
                    <div className='card-design'>
                        <div className='about-list-item'>
                            <div className='about-details-icon'>
                                <p>
                                    <FcGlobe size={50}/>
                                </p>
                            </div>
                            <div>
                                <h5 color='aqua'>Our Journey</h5>
                                <p color='white'> started in the North East of England, where we realised 
                                    that many small businesses struggle to compete with larger companies 
                                    due to limited resources and access to data-driven insights. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <div className='card-design'>
                        <div className='about-list-item'>
                            <div className='about-details-icon'>
                                <p>
                                    <FcShipped size={50}/>
                                </p>
                            </div>
                            <div>
                                <h5 color='aqua'>Our Mission</h5>
                                <p color='white'>
                                    is to democratise data science and empower businesses 
                                    of all sizes with data-driven insights for informed decision-making 
                                    and growth. 
                                </p>
                            </div>
                        </div>
                        <p></p>
                    </div>
                    <div className='card-design'>
                        <div className='about-list-item'>
                            <div className='about-details-icon'>
                                <p>
                                    <FcStatistics size={50}/>
                                </p>
                            </div>
                        <div>
                            <h5 color='aqua'>With our Platform</h5>
                            <p color='white'> you will be able to unlock the power 
                            of data analytics to make informed decisions and stay competitive in 
                            the data-driven world.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>


            </div>
            </Fade>
        </div>
    </div>
  )
}

export default About