import React from 'react'
import './footer.css'
import {BsTwitter, BsFacebook, BsInstagram} from 'react-icons/bs'

const Footer = () => {
    return (
      <div className='container-fluid footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                <div className='footer-service'>
                    <h5>About</h5>
                    <div className='footer-item' style={{ textAlign: 'left' }}>
                    <p color='white'>Domesday Analytics is a cutting-edge data-driven company 
                       specializing in Data Analysis, Machine Learning (ML) & 
                       Artificial Intelligence (AI), and Web Development. 
                       Our expert team leverages data analysis techniques to extract 
                       valuable insights from raw data, enabling data-driven decision-making. 
                       With proficiency in ML/AI, we develop predictive models and implement 
                       AI solutions to automate processes and enhance efficiency.  
                       At Domesday Analytics, we are dedicated to delivering innovative solutions 
                       tailored to meet your business needs.</p>
                    </div>
                </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                {/* Leave this empty to create a space between about and contact sections */}
                </div>
              
            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
              <div className='footer-about-section'>
                          <h5>Stay Connected:</h5>
                          <p color='white'>
                            Follow us on social media for the latest updates, insights, 
                            and exciting announcements. Join our vibrant community and 
                            be part of the conversation. Together, let's shape the future with 
                            innovation and knowledge! #DomesdayAnalytics
                          </p>
                          <span className='footer_social-icons'>
                              <p>
                                  <a href="https://www.facebook.com/">
                                      <BsFacebook size={30}/>
                                  </a>
                              </p>
                              <p>
                                  <a href="https://www.instagram.com/domesdayanalytics/">
                                      <BsInstagram size={30}/>
                                  </a>
                              </p>
                              <p>
                                  <a href='https://twitter.com/domesdayltd'>
                                      <BsTwitter size={30}/>
                                  </a>
                              </p>
                          </span>
                          <p color='white'>All rights reserved &copy; Domesday Analytics 2023</p>
                      </div>
                  </div>
                 
          </div>
        </div>
      </div>
    )
  }
  
  export default Footer;