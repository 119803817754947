import React from 'react'
import './services.css'
import {FcRatings, FcElectronics} from 'react-icons/fc'
import {CgWebsite} from 'react-icons/cg'
import Zoom from 'react-reveal/Zoom';


const Services = () => {

    const servicedata = [
        {
            id:1,
            service_name:"Data Analysis",
            service_icon:<FcRatings size={50}/>, 
            job_list: ['We utilise data analysis techniques to gain insights from raw data.',
            'Identify patterns, trends, and correlations to make informed decisions.',
            'Apply statistical methods and visualisation tools to present findings effectively.',
            'We excel in data cleaning, transformation, and data-driven decision-making.']
        },
        {
            id:2,
            service_name:"Machine Learning / Artificial Intelligence (AI):",
            service_icon:<FcElectronics size={50}/>, 
            job_list: ['Harness the power of machine learning algorithms for predictive modeling.',
            'Implement AI solutions to automate processes and enhance efficiency.',
            'Expertise in supervised and unsupervised learning for classification and clustering.',
            'Develop natural language processing (NLP) models for text analysis.']
        },
        {
            id:3,
            service_name:"Web Development",
            service_icon:<CgWebsite size={50} color='hotpink'/>, 
            job_list: ['Craft dynamic and responsive web applications using cutting-edge technologies.',
            'Proficient in HTML, CSS, JavaScript, and modern front-end frameworks.',
            'Create server-side applications with back-end technologies',
            'Integrate databases and APIs to build interactive and data-driven web solutions.']
        },
    ]
  return (
    <div className='container service-section'id='service'>
        <div className='service-title'>
            <h3>Services</h3>
            <span className='line'></span>
        </div>

        <div className='row'>
        {servicedata.map((item) => (
          <Zoom key={item.id}>
            <div className="col-xl-3 col-lg-4 col-md-4">
                <div className="services">
                  <span className="service-icon">{item.service_icon}</span>
                  <div className="service_name">
                    <h5>{item.service_name}</h5>
                  </div>
                  <span className="service_item_underline"></span>
                  <div className="list-of-services">
                    {item.job_list.map((job) => (
                      <p className="job-item" key={job}>
                        <span className="bullet-point"></span>
                        {job}
                      </p>
                    ))}
                    </div>
                    </div>
                  </div>
                  
          </Zoom>
              ))}
        </div>
    </div>
  )
}

export default Services